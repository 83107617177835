import React from 'react';
import './HowItWorks.css';
import starfinHomeVid from '../../assets/images/starfin_animation.mp4';

const HowItWorks = () => {
  return (
    <div className='home-container-how-it-works'>
      <h2 className='home-container-how-it-works-text'>How It Works!</h2>
      <video 
        className='home-container-how-it-works-video' 
        src={starfinHomeVid} 
        controls
        frameborder="0" 
        allow="clipboard-write" 
        allowfullscreen>
      </video>
    </div>
  );
}

export default HowItWorks;
