import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../../firebase/AuthProvider';
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebase/config';  
import './Profile.css';
import { useNavigate } from 'react-router-dom';
import Listing from './Listing';

const Profile = () => {

  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

  const { currentUser, userRole } = useContext(AuthContext);
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const [approvedListingsFromDB, setApprovedListingsFromDB] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('approved');
  const [searchbar, setSearchBar] = useState('');
  const [filteredListings, setFilteredListings] = useState()

  useEffect(() => {
    const fetchListings = async () => {
      let url = `${apiUrl}/api/getListingsWithEmail?email=${currentUser.email}`;

      if (userRole === 'admin') {
        url = `${apiUrl}/api/getAllPendingListings`; 
      }

      try {
        const response = await axios.get(url);
        setListings(response.data);
      } catch (error) {
        console.error("Error fetching listings: ", error);
      }
    };

    if (currentUser && currentUser.email) {
      fetchListings();
    }
  }, [currentUser, userRole]);

  // Fetch all approved listings fir admin account
  useEffect(() => {
    const fetchApprovedListings = async () => {
      
      if (userRole !== 'admin') return;
  
      const queryParams = { limit: 10, offset: 0 };
      const queryString = new URLSearchParams(queryParams).toString();
      const url = `${apiUrl}/api/getApprovedListings?${queryString}`;
  
      try {
        const response = await axios.get(url);
        // Correctly set the state with the fetched data (FOR ADMINS)
        setApprovedListingsFromDB(response.data.listings || []);
      } catch (error) {
        console.error("Error fetching approved listings: ", error);
      }
    };
  
    fetchApprovedListings();
  }, [userRole]);

  const approvedListings = listings.filter(listing => listing.isApproved);
  const pendingListings = listings.filter(listing => !listing.isApproved && !listing.isRejected);
  const rejectedListings = listings.filter(listing => listing.isRejected);
  
  const logOut = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in');
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSearchBar('');
  };

  const displayedListings = 
    selectedCategory === 'approved' ? (userRole === 'admin' ? approvedListingsFromDB : approvedListings) : 
    selectedCategory === 'rejected' ? rejectedListings : 
    pendingListings;


  const handleSearchBar = (e) => {
      setSearchBar(e.target.value);
      console.log("Search bar updated") 

      setFilteredListings(displayedListings.filter(listing => {
        const searchLower = searchbar.toLowerCase().split(' ').map(term => term.trim());
        return searchLower.some(term =>  
          (listing.commercialPropertyAddress && listing.commercialPropertyAddress.toLowerCase().includes(term)) ||
          (listing.firstname && listing.firstname.toLowerCase().includes(term))
        );
      }));
      

  }

  return (
    <div className='profile-container'>
      <div className='profile-container-log-out'>
        <button className='profile-container-log-out-button' onClick={logOut}> Log Out </button>
      </div>
      <div className='profile-container-title'>
        { userRole === 'admin' ? ( 
        <h3> Admin: All listings </h3> ) : ( <h3>My Listings </h3>)
      }
      </div>

      
      {userRole == "admin" && 
      <div className='profile-container-search-bar'>
        <input 
        type='text' 
        value={searchbar} 
        onChange={handleSearchBar} 
        placeholder='Search Listings... (admin access)'
        />
      </div>
      } 

      <div className='profile-listing-container'>
        <div className='listing-container-titles'>
          <div className={`listing-container-titles-title ${selectedCategory === 'approved' ? 'selected' : ''}`}
               onClick={() => handleCategoryClick('approved')}>
              Approved ({approvedListings.length})
          </div>
          <div className={`listing-container-titles-title ${selectedCategory === 'pending' ? 'selected' : ''}`}
               onClick={() => handleCategoryClick('pending')}>
              Pending ({pendingListings.length}) 
          </div>
          <div className={`listing-container-titles-title ${selectedCategory === 'rejected' ? 'selected' : ''}`}
               onClick={() => handleCategoryClick('rejected')}>
              Rejected ({rejectedListings.length}) 
          </div>
        </div>
        <div className='listing-container-approved-or-pending'>
          

          {searchbar.length != 0 ? filteredListings.map(listing => (
            <Listing key={listing.id} {...listing} role={userRole} userEmail={currentUser.email} />
          )): displayedListings.map(listing => (
            <Listing key={listing.id} {...listing} role={userRole} userEmail={currentUser.email} />
          ))}
          
        </div>
      </div>
    </div>
  );
}

export default Profile;