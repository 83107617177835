import React from 'react'
import './WhatWeDo.css'
import homeWhatWeDo from '../../assets/images/home-what-we-do.webp'

const WhatWedo = () => {
  return (
    <div className='home-container-what-we-do-container'>
      <div className='home-container-what-we-do-container-left-side'>
        <div className='home-container-what-we-do-container-left-side-title'> WHAT WE DO </div>
        <div className='home-container-what-we-do-container-left-side-first-paragraph'> An Innovative Approach to Establishing a Connection With A Clean Energy Source </div>
        <div className='home-container-what-we-do-container-left-side-second-paragraph'> We assist commercial property owners in successfully concluding clean energy transactions while earning top-tier transaction fees. StarFin guides you through project feasibility, EPC & PPA acquisition, energy risk management, and other aspects, enabling the provision of cutting-edge clean energy services to commercial real estate (CRE) owners and tenants. </div>
        <div className='home-container-what-we-do-container-left-side-third-paragraph'> We guarantee the enhancement of your property’s value and its maximum potential by collaborating with leading national entities and the most forward-thinking companies in the clean energy sector. Rely on our transparent and competitive bidding process, which exposes your project to all interested parties.</div>
        <div className='home-container-what-we-do-container-left-side-fourth-paragraph'> Together, we’ll shape a cleaner tomorrow. One profitable decision at a time.  </div>
      </div>
      <div className='home-container-what-we-do-container-right-side'>
        <img className='home-container-what-we-do-container-right-side-image' src={homeWhatWeDo} />
      </div>
    </div>
  )
}

export default WhatWedo
