// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { signInWithPopup } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgD1HcGiLmOG8I51adhfcKmh8JIO7z9_I",
  authDomain: "starfinventures.firebaseapp.com",
  projectId: "starfinventures",
  storageBucket: "starfinventures.appspot.com",
  messagingSenderId: "400280274413",
  appId: "1:400280274413:web:81d3240182731c2a2453e4",
  measurementId: "G-67F0WHSLCF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider };