import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import './CommisionAndReferralProgram.css'
import arrowImage from '../../assets/images/arrow_image.png'
import carpFirst from '../../assets/images/carp-first.webp'
import carpSecond from '../../assets/images/carp-second.png'
import carpThird from '../../assets/images/carp-third.webp'
import carpFourth from '../../assets/images/carp-fourth.webp'



const CommisionAndReferralProgram = () => {

  const navigate = useNavigate();

  const navigateToListProperty = () => {
    navigate('/list-property');
  }

  const navigateToContactUs = () => {
    navigate('/contact-us');
  }

  return (
    <div className='commision-and-referral-program-container'>

      <div className='commision-and-referral-program-card'>
        <div className='commision-and-referral-program-card-left'>
          <div className='commision-and-referral-program-card-title'>
            Welcome
          </div>
          <div className='commision-and-referral-program-card-text'>
          Welcome to the referral and commission program — your go-to-destination for unlocking lucrative opportunities in commercial rooftop spaces! Whether you’re a dedicated realtor, an individual with a passion for real estate, or a property manager shaping the landscape, we’re thrilled to have you. Dive into our exclusive programs — the Realtor Commission Program, Referral Program for Non-Realtor Individuals, and Property Manager Commission Program — where your efforts are recognized and generously rewarded. Explore, connect, and prosper with us at StarFin as we collectively shape the future of real estate. Your journey to success starts here!
          </div>
        </div>
        <div className='commision-and-referral-program-card-right'>
            <img className='commision-and-referral-program-card-image-1' src={carpFirst} />
        </div>
      </div>

      <div className='commision-and-referral-program-card'>
        <div className='commision-and-referral-program-card-left'>
          <img className='commision-and-referral-program-card-image-2' src={arrowImage} />
        </div>
        <div className='commision-and-referral-program-card-right'>
            <div className='commision-and-referral-program-card-title-2'>
              PROPERTY MANAGER COMMISION PROGRAM
            </div>
            <div className='commision-and-referral-program-card-text'>
            At StarFin, we recognize the invaluable role that property managers play in the real estate landscape. That’s why we’re excited to introduce our exclusive Property Manager Commission Program — a rewarding opportunity for property managers to boost their income while facilitating successful deals with landlords. Fill out the form below to set a meeting and learn how you can maximize your potential.
            </div>
            <button onClick={navigateToListProperty} className='carp-list-property'> List Property </button>        </div>
      </div>

      <div className='commision-and-referral-program-card'>
        <div className='commision-and-referral-program-card-left'>
          <div className='commision-and-referral-program-card-title-2'>
            NON-REALTOR INDIVIDUAL & COMPANY REFERRAL PROGRAM
          </div>
          <div className='commision-and-referral-program-card-text'>
            At StarFin, we believe in the power of community and the value of meaningful connections. That’s why we’re thrilled to introduce our Referral Program for Non-Realtor Individuals —a fantastic opportunity for you to earn substantial commissions by referring us to landlords. Fill out the form below to set a meeting and learn how you can maximize your potential.
          </div>
          <button onClick={navigateToContactUs} className='carp-list-property'> Contact Us! </button>
        </div>
        <div className='commision-and-referral-program-card-right'>
            <img className='commision-and-referral-program-card-image-1' src={carpThird} />
        </div>
      </div>

      <div className='commision-and-referral-program-card'>
        <div className='commision-and-referral-program-card-left'>
          <img className='commision-and-referral-program-card-image-2' src={carpFourth} />
        </div>
        <div className='commision-and-referral-program-card-right'>
            <div className='commision-and-referral-program-card-title-2'>
            REALTORS COMMISSION PROGRAM
            </div>
            <div className='commision-and-referral-program-card-text'>
            In the dynamic world of real estate, we understand the hard work and dedication that realtors invest in every transaction. Realtors earn income and commissions based on the parties they represent in a transaction. The commission amount is mutually agreed upon between the realtor and the property owner, ensuring a fair and transparent process.
            </div>
        </div>
      </div>

      <div className='commision-and-referral-program-card-2'>
        <div className='commision-and-referral-program-card-title-2'>
            DISCLAIMER
        </div>

        <div className='commision-and-referral-program-card-text-2'>
        When an individual or entity refers us to another property manager, the percentage of the commission will be split evenly between both parties. There will not be two different commissions; instead, we ensure a fair and equitable distribution, recognizing the collaborative effort in the referral process
        </div>
      </div>

    </div>
  )
}

export default CommisionAndReferralProgram;
