import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Home from "./components/Home/Home";
import Navbar from './components/common/Navbar/Navbar';
import SignUpIn from './components/sign-up-in/SignUpIn';
import Profile from './components/sign-up-in/Profile/Profile';
import ListProperty from './components/common/Navbar/ListProperty';
import Footer from './components/common/Navbar/Footer';
import GetAnEstimate from './components/Get an Estimate/GetAnEstimate'
import { AuthProvider } from './firebase/AuthProvider';
import CommisionAndReferralProgram from './components/Commission and Referral Program/CommisionAndReferralProgram';
import FAQsPage from './components/FAQs/FAQsPage';
import ContactUs from './components/ContactUs/ContactUs';
import ViewProperties from './components/view-properties/ViewProperties';
import ForgotPassword from './components/sign-up-in/ForgotPassword';
import ListingPage from './components/common/ListingPage';

//import GetAnEstimate from './components/Get an Estimate/GetAnEstimate'
const App = () => {
  return (
    <div>
      <Router>
        <AuthProvider>
        <Navbar />
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/sign-in' element={<SignUpIn />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/list-property' element={<ListProperty />} />
            <Route path='/get-an-estimate' element={<GetAnEstimate />} />
            <Route path='/commission-and-referral-program' element={<CommisionAndReferralProgram />} />
            <Route path='/faqs' element={<FAQsPage />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/view-properties' element={<ViewProperties />} />
            <Route path='/property/:id' element={<ListingPage />} />
            <Route path='/reset-password' element={<ForgotPassword />} />
        </Routes>
        <Footer />
        </AuthProvider>
      </Router>
    </div>
  )
}

export default App
