import React from 'react';
import './FAQsPage.css';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import arrowAndButton from '../../assets/images/faq-arrow-button.PNG';
import { useState } from 'react';
import FAQ from './FAQ';

import {useNavigate} from 'react-router-dom';

const FAQsPage = () => {

    const navigate = useNavigate();

    const [faqs, setFaqs] = useState([
        {
            question: "As a property owner, do I have to make any investments or financial commitments to be able to generate revenue from my roof?",
            answer: "No. That’s the beauty of our business model and approach. A chosen investor from our network will be paying for all the costs related to your projects. They will directly deal with the sales and harvesting of the produced electricity and they will be paying you monthly rent for giving them the opportunity to use your roof space and produce electricity.",
            open: false
        },
        {
            question: "What happens if the solar panels require maintenance or repairs?",
            answer: "The investor assumes responsibility for the maintenance and repairs of the solar panels throughout the rental period. Reputable solar panel manufacturers typically provide warranties that may cover certain maintenance aspects.",
            open: false
        },
        {
            question: "Can commercial properties with existing rooftop structures still participate in the program?",
            answer: "Yes, commercial properties with existing rooftop structures can still participate, as long as there is unutilized space available for solar panel installations. StarFin can assess the rooftop’s suitability during the evaluation process.",
            open: false
        },
        {
            question: "What happens at the end of the rental agreement between the property owner and the investor?",
            answer: "At the end of the rental agreement, the investor may choose to remove the solar panels, renew the agreement, or negotiate a new arrangement with the property owner, depending on the mutual interests of both parties.",
            open: false
        },
        {
            question: "What are the potential environmental benefits of participating in StarFin’s clean energy initiative?",
            answer: "By participating in StarFin’s initiative, commercial property owners and investors contribute to reducing carbon emissions, promoting renewable energy adoption, and supporting a cleaner and more sustainable environment for future generations.",
            open: false
        },
        {
            question: "Are there any financial incentives or tax benefits for investors and property owners participating in the program?",
            answer: "Yes, investors and property owners may be eligible for various financial incentives and tax benefits, which vary depending on the location and prevailing government policies. StarFin can provide information on available incentives during the partnership discussions.",
            open: false
        },
        {
            question: "How does StarFin identify suitable commercial properties for solar panel installations?",
            answer: "StarFin utilizes a comprehensive assessment process to identify commercial properties with unutilized rooftop space that are ideal for solar panel installations. Factors considered include rooftop size, orientation, shading, and structural integrity.",
            open: false
        },
        {
            question: "What criteria does StarFin use to select reputable solar panel installers?",
            answer: "StarFin carefully evaluates solar panel installers based on their track record, certifications, product quality, warranties, and overall reputation in the industry. Only trusted installers are recommended to investors.",
            open: false
        },
        {
            question: "Does StarFin sell solar panels or act as a service provider?",
            answer: "No, StarFin does not sell tangible products. It operates as a platform that facilitates connections between investors, commercial property owners, and solar panel installers.",
            open: false
        }  
    ])


    const toggleFAQ = index => {
        setFaqs(faqs.map((faq, i) => {
            if (i == index) {
                faq.open = !faq.open;
                console.log(faq, 'result: ', faq.open)
            }
            return faq;
        }))
    }

    const handleNavigate = () => {
        navigate('/contact-us');
    };

  return (
    <div className='faqsPage-container'>
      <div className='faqsPage-container-top'>
        <div className='faqsPage-container-top-left'>
            <div className='faqsPage-container-top-left-first-text'>
                WE ARE HERE TO HELP YOU
            </div>
            <div className='faqsPage-container-top-left-second-text'>
                FAQs
            </div>
            <div className='faqsPage-container-top-left-third-text'>
                Have Questions? Here you'll find the answers most valued by our partners, along with access to step-by-step instruction and support
            </div>
        </div>
        <div className='faqsPage-container-top-right'>
            <img className='faqsPage-container-top-right-image' src='https://img.freepik.com/free-vector/thoughtful-woman-with-laptop-looking-big-question-mark_1150-39362.jpg' />
        </div>
      </div>
      <div className='faqsPage-container-bottom'>
        <div className='faqsPage-container-bottom-left'>
            <div className='faqsPage-container-bottom-left-text-first'>
                Can't find what you're looking for?
            </div>
            <div className='faqsPage-container-bottom-left-text-second'>
                We would like to chat with you.
            </div>
            <ContactSupportIcon onClick={handleNavigate} className='faqsPage-container-bottom-left-button' sx={{fontSize: '80px', color: '#044FE7', margin: '5px'}} />
        </div>
        <div className='faqsPage-container-bottom-right'>
            {faqs.map((faq, i) => {
                return <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
            })} 
        </div>
      </div>
    </div>
  )
}

export default FAQsPage;
