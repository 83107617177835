import "./AutoComplete.css";
import { useState } from "react";
import axios from 'axios'


export default function AutoCompleteInput({handleManualInputChange, setAddress, streetAndNumber,}) {

    async function getPlaces(query) {
        try {
            const response = await axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`,
            {
                params: {
                    access_token: 'pk.eyJ1Ijoic2FtbXlrYW8iLCJhIjoiY2x1aW54amplMDZwODJycDVxcnc4aGRjZyJ9.qO4vaM2TzcdFSMDoHLalxA',
                },
            }
            );
            console.log(response);
            return response.data.features;
        } catch (error) {
            console.error("There was an error while fetching places:", error);
        }
    }

    const [suggestions, setSuggestions] = useState([]);

    const handleChange = (event) => {
        handleManualInputChange(event, "streetAndNumber");
        handleInputChange(event.target.value);
    };

    const handleInputChange = async (query) => {
        const suggesions = await getPlaces(query);
        setSuggestions(suggesions);
    };

    const handleSuggestionClick = (suggestion) => {
        const streetAndNumber = suggestion.place_name.split(",")[0];
        const latitude = suggestion.center[1];
        const longitude = suggestion.center[0];

        const address = {
        streetAndNumber,
        place: "",
        region: "",
        postcode: "",
        country: "",
        latitude,
        longitude,
        };

        suggestion.context.forEach((element) => {
        const identifier = element.id.split(".")[0];

        address[identifier] = element.text;
        });

        setAddress(address);
        setSuggestions([]);

        // handleManualInputChange({ target: { value: streetAndNumber } }, "streetAndNumber");
        // handleManualInputChange({ target: { value: address.place } }, "place");
        // handleManualInputChange({ target: { value: address.region } }, "region");
        // handleManualInputChange({ target: { value: address.postcode } }, "postcode");
        // handleManualInputChange({ target: { value: address.country } }, "country");
    };

    return (
        <div>
        <div className="autoCompleteInputContainer">
            <input
            id="address"
            type="text"
            placeholder="Address"
            value={streetAndNumber}
            onChange={handleChange}
            />
            <ul className="addressSuggestions">
            {suggestions?.map((suggestion, index) => (
                <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion.place_name}
                </li>
            ))}
            </ul>
        </div>
        </div>
    );
}