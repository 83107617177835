import React from 'react';
import './Steps.css';
import stepOne from '../../assets/images/home-step-one.webp'
import stepTwo from '../../assets/images/home-step-two.webp'
import stepThree from '../../assets/images/home-step-three.webp'

const StepCard = ({ imgUrl, text }) => {
  return (
    <div className="step-card">
      <img className='step-image' src={imgUrl} />
      <p className="step-text">{text}</p>
    </div>
  );
};

const StepProgress = () => {
  return (
    <div className="step-progress">
      <StepCard imgUrl={stepOne} text="Fill Out Meeting Request Form" />
      <StepCard imgUrl={stepTwo} text="Have a short 30 minute meeting" />
      <StepCard imgUrl={stepThree} text="We’ll launch your project & be back with best offers!" />
    </div>
  );
};

export default StepProgress;
