import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../firebase/AuthProvider';
import axios from 'axios';
import './ListProperty.css';
import { State, City } from 'country-state-city';
import listPropertyImage from '../../../assets/images/list-property-image.jpg';

const ListProperty = () => {
    const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";
    const { currentUser, loading } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isUploading, setIsUploading] = useState(false);
    const [imageInputs, setImageInputs] = useState([0]); 
    const [selectedState, setSelectedState] = useState('');
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: currentUser ? currentUser.email : '',
        commercialPropertyAddress: '',
        companyName: '',
        positionOfLister: '',
        isApproved: false,
        isRejected: false,
        imageURLs: [] ,
        state: '',
        city: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (!loading && !currentUser) {
          navigate('/sign-in');
        } else if (currentUser && !loading) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            email: currentUser.email,
          }));
        }
      }, [currentUser, loading, navigate]);

    const handleStateChange = (e) => {
        setSelectedState(e.target.value);
        setFormData({...formData, state: e.target.value, city: ''}); // resetting city on state change
    };

    const handleChange = async (e, index) => {
        if (e.target.name === `imageURLs${index}`) {
            setIsUploading(true);
            const file = e.target.files[0];
            const response = await axios.get(`${apiUrl}/api/generate-presigned-url`, {
                params: { fileName: file.name }
            });
            const { url } = response.data;
            await axios.put(url, file, {
                headers: { 'Content-Type': 'image/jpeg' },
            });
            const imageUrl = url.split('?')[0];
            
            setFormData(prevFormData => ({
                ...prevFormData,
                imageURLs: [...prevFormData.imageURLs, imageUrl] 
            }));
            setIsUploading(false);

            // Add a new file(image) input if current one is last.
            if ((index === imageInputs.length - 1) && imageInputs.length < 7) {
                setImageInputs(inputs => [...inputs, inputs.length]);
            }
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [e.target.name]: e.target.value
            }));
        }
    };
    const validateForm = () => {
        const newErrors = {};

        if (!formData.firstname.trim()) newErrors.firstname = 'First Name is required';
        if (!formData.lastname.trim()) newErrors.lastname = 'Last Name is required';
        if (!formData.email.trim()) newErrors.email = 'Email is required';
        if (!formData.commercialPropertyAddress.trim()) newErrors.commercialPropertyAddress = 'Address is required';
        if (!formData.state.trim()) newErrors.state = 'State is required';
        if (!formData.city.trim()) newErrors.city = 'City is required';
        if (!formData.companyName.trim()) newErrors.companyName = 'Company Name is required';
        if (!formData.positionOfLister.trim()) newErrors.positionOfLister = 'Position is required';
        if (!formData.imageURLs[0]) newErrors.imageURLs = 'At least one image is required';
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0; // if no errors, form is valid
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        if (!isUploading && formData.imageURLs.length > 0) {
            // console.log("Final formData for submission:", formData); // REMOVE
            try {
                const response = await axios.post(`${apiUrl}/api/listProperty`, formData, {
                    headers: { 'Content-Type': 'application/json' },
                });
                // onsole.log("Listing Submitted: ", response.data); // REMOVE
                navigate('/profile');
            } catch (error) {
                console.error("Error submitting Listing: ", error);
            }
        } else {
            // console.log("Waiting for images to finish uploading or no images uploaded."); // REMOVE
        }
    };

    return (
        <div className='list-property'>
            {currentUser && !currentUser.emailVerified ? (
                <div className='verification-message-container'>
                    <p className="list-property-verification-message">Please verify your email to list properties.</p>
                </div>
            ) : (
            <div className='list-property-verified-container'>
                <div className='list-property-left-side-container'>
                    <div className='list-property-left-side-text'> 
                        Join the StarFin Ventures <span>Green Dream</span> 
                    </div>
                    <img className='list-property-left-side-image' src={listPropertyImage} />
                </div>
                <div className='list-property-right-side-container'>    
                    <form className='list-property-form-container' onSubmit={handleSubmit}>
                        {errors.firstname && <p className="error-message">{errors.firstname}</p>}
                        <input className='list-property-form-input' onChange={(e) => handleChange(e)} placeholder="First Name" name="firstname"></input>
                        {errors.lastname && <p className="error-message">{errors.lastname}</p>}
                        <input className='list-property-form-input' onChange={(e) => handleChange(e)} placeholder="Last Name" name="lastname"></input>
                        {errors.email && <p className="error-message">{errors.email}</p>}
                        <input className='list-property-form-input' type="email" placeholder="Email" name="email" value={currentUser?.email || ''} readOnly />
                        {errors.commercialPropertyAddress && <p className="error-message">{errors.commercialPropertyAddress}</p>}
                        <input className='list-property-form-input' onChange={(e) => handleChange(e)} placeholder="Commercial Property Address" name="commercialPropertyAddress"></input>
                        <div className='list-property-state-city-inputs-container'>
                            <select className='list-property-state-city-input' value={formData.state} name="state" onChange={handleStateChange}>
                                <option value="">Select State</option>
                                {State.getStatesOfCountry('US').map((state) => (
                                    <option key={state.isoCode} value={state.isoCode}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                            {errors.state && <p className="error-message">{errors.state}</p>}
                            <select className='list-property-state-city-input' value={formData.city} name="city" onChange={(e) => setFormData({...formData, city: e.target.value})}>
                                <option value="">Select City</option>
                                {City.getCitiesOfState('US', selectedState).map((city) => (
                                    <option key={city.name} value={city.name}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                            {errors.city && <p className="error-message">{errors.city}</p>}
                        </div>
                        <div className='list-property-company-position-container'>
                            <input className='list-property-company-position-input' onChange={(e) => handleChange(e)} placeholder="Company Name" name="companyName"></input>
                            <input className='list-property-company-position-input' onChange={(e) => handleChange(e)} placeholder="Position" name="positionOfLister"></input>
                        </div>
                        <div>
                            <h5> Upload Images</h5>
                            <div className='list-property-upload-images-container'>
                                {errors.imageURLs && <p className='error-message'> {errors.imageURLs} </p>}
                                {imageInputs.map((input, index) => (
                                    <input className='list-property-form-image-input' key={index} type="file" name={`imageURLs${index}`} onChange={(e) => handleChange(e, index)} multiple accept="image/*" />
                                ))}
                            </div>
                        </div>
                        <div className='list-property-form-submit-container'>
                            <button className='list-property-form-submit-button' type='submit' disabled={isUploading}>Submit Listing</button>
                        </div>
                    </form>
                </div>
            </div>
            )}
        </div>
    );
};

export default ListProperty;
