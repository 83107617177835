import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Logo from '../../../assets/images/logo.webp';
import './Navbar.css';

import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';

import { createTheme, Menu, ThemeProvider} from '@mui/material';
import { AuthContext } from '../../../firebase/AuthProvider';


const buttonTheme = createTheme({
  palette: {
    primary: {
      main: '#B2C3FD'
    }
  }
})

const Navbar = () => {


  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isHamburgerMenuActive, setIsHamburgerMenuActive] = useState(false);



  function toggleHamburgerMenu() {
    setIsHamburgerMenuActive((prevState) => !prevState);
  }

  function displaySignIn(){
    return (
      <Link to='/sign-in'>
          <li className='nav-bar-list-element'> Sign up / Sign in </li>
      </Link>
    )
  }

  function displayProfile(){
    return (
      <Link to='/profile'>
          <li className='nav-bar-list-element'> Profile </li>
      </Link>
    )
  }

  const navigateToListProperty = () => {
    navigate('/list-property');
  }



  const navigateToViewProperties = () => {
    navigate('/view-properties', { replace: true, state: { refresh: true } });
  }
  const navigateToCommision = () => {
    navigate('/commission-and-referral-program', { replace: true, state: { refresh: true } });
  }
  const navigatetoEstimate = () => {
    navigate('/get-an-estimate', { replace: true, state: { refresh: true } });
  }
  const navigateToFAQs = () => {
    navigate('/faqs', { replace: true, state: { refresh: true } });
  }
  const navigateToContactUs = () => {
    navigate('/contact-us', { replace: true, state: { refresh: true } });
  }
  const navigateToProfile = () => {
    navigate('/profile', { replace: true, state: { refresh: true } });
  }
  const navigateToSignIn = () => {
    navigate('/sign-in', { replace: true, state: { refresh: true } });
  }


  const navigateAndToggleMenu = (path) => {
    setIsHamburgerMenuActive(false);
    navigate(path, { replace: true, state: { refresh: true } });
  };

  return (
    <div className='nav-bar-container'>
      <div className='nav-bar-left-side'> 
        <a href='/'>
          <img className='nav-bar-logo' src={Logo} />
        </a>
        <ul className={`nav-bar-list ${isHamburgerMenuActive ? 'hamburger-menu active' : ''}`}>
          <div className='nav-bar-close-icon'>
            <ClearIcon sx={{fontSize: '40px'}} onClick={toggleHamburgerMenu} />
          </div>
          <li className='nav-bar-list-element' onClick={() => navigateAndToggleMenu('/')}> Home </li>
          <li className='nav-bar-list-element' onClick={() => navigateAndToggleMenu('/commission-and-referral-program')}> Commission and Referral Program </li>
          <li className='nav-bar-list-element' onClick={() => navigateAndToggleMenu('/get-an-estimate')}> Get An Estimate </li>
          <li className='nav-bar-list-element' onClick={() => navigateAndToggleMenu('/faqs')}> FAQs </li>
          <li className='nav-bar-list-element' onClick={() => navigateAndToggleMenu('/contact-us')}> Contact Us </li>
          <div>
            {currentUser ? <li className='nav-bar-list-element' onClick={() => navigateAndToggleMenu('/profile')}> Profile </li>
              : <li className='nav-bar-list-element' onClick={() => navigateAndToggleMenu('/sign-in')}> Sign up / Sign in </li>}
          </div>
        </ul>
        <div className='nav-bar-menu-icon'>
          <MenuIcon sx={{fontSize: '33px'}} onClick={toggleHamburgerMenu}/>
        </div>
      </div>
      <div className='nav-bar-right-side'>
        <button onClick={() => navigateAndToggleMenu('/list-property')} className='nav-bar-right-side-button'> List Property </button>
        <button onClick={() => navigateAndToggleMenu('/view-properties')} className='nav-bar-right-side-button'> View Properties </button>
      </div>
    </div>
  );
}

export default Navbar
