import "./AddressForm.css";
import AutoCompleteInput from "./AutoComplete";

export default function AddressForm({ address, onSubmit, setAddress }) {
    const handleManualInputChange = (event, stateProperty) => {
        const newAddress = { ...address };
        newAddress[stateProperty] = event.target.value;

        setAddress(newAddress);
    };

    return (
        <form className="address-form-form" onSubmit={onSubmit}>
            <AutoCompleteInput
                setAddress={setAddress}
                handleManualInputChange={handleManualInputChange}
                streetAndNumber={address.streetAndNumber}
            />

            <input
                type="text"
                id="city"
                placeholder="City"
                value={address.place}
                onChange={(event) => handleManualInputChange(event, "place")}
            />

            <input
                type="text"
                id="state"
                placeholder="State/Province/Region"
                value={address.region}
                onChange={(event) => handleManualInputChange(event, "region")}
            />

            <input
                type="text"
                id="postcode"
                placeholder="Postcode"
                value={address.postcode}
                onChange={(event) => handleManualInputChange(event, "postcode")}
            />

            <input
                type="text"
                id="country"
                placeholder="Country"
                value={address.country}
                onChange={(event) => handleManualInputChange(event, "country")}
            />

            <div className="buttons">
                <button type="submit" className="confirm-button">
                CONFIRM
                </button>
                <button
                type="reset"
                className="reset-button"
                onClick={() =>
                    setAddress({
                    streetAndNumber: "",
                    place: "",
                    region: "",
                    postcode: "",
                    country: "",
                    latitude: "",
                    longitude: "",
                    })
                }
                >
                RESET
                </button>
            </div>
        </form>
    );
}