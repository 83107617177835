import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './ListingPage.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const ListingPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

    const { id } = useParams();
    const [property, setProperty] = useState({ imageURLs: [] });
    const [currentIndex, setCurrentIndex] = useState(0);
    const sets="N/A"
    useEffect(() => {
        fetchPropertyDetails();
    }, []);

    const fetchPropertyDetails = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/getListingsWithId/${id}`);
            setProperty(response.data);
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const nextImage = () => {
        if (currentIndex < property.imageURLs.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const previousImage = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };


    return (
        <div className='listing-page-container'>
            <div className='listing-page-image-slider-container'>
                {currentIndex > 0 && <ArrowBackIosIcon className="listing-page-arrow-back" onClick={previousImage} />}
                <img className='listing-page-main-image' src={property.imageURLs[currentIndex]} alt="" />
                {currentIndex < property.imageURLs.length - 1 && <ArrowForwardIosIcon className="listing-page-arrow-forward" onClick={nextImage} />}
            </div>
            <div className="listing-page-box">
                <div className="listing-page-property-location-box">
                    <h2>{property.city}, {property.state}</h2>
                </div>
                <div className="listing-page-big-box">
                    <div className="listing-page-property-specific-details-box">
                        <div className="listing-page-property-specific-details-one-by-one-box">
                            <h3>Average yearly income</h3>
                            <p>{sets}</p>
                        </div>
                        <div className="listing-page-property-specific-details-one-by-one-box">
                            <h3>Size</h3>
                            <p>{sets}</p>
                        </div>
                        <div className="listing-page-property-specific-details-one-by-one-box">
                            <h3>Lease term</h3>
                            <p>{sets}</p>
                        </div>
                        <div className="listing-page-property-specific-details-one-by-one-box">
                            <h3>Building value increase</h3>
                            <p>{sets}</p>
                        </div>
                    </div>

                    <div className="listing-page-individual-listing-contact-us">
                        <h2>Contact us</h2>
                        <p>We welcome your calls and emails</p>
                        <a href="/contact-us" class="contact-link">Contact Form</a>
                        <a class="contact-detail" href="tel:+1 (925) 381-3895"><span class="contact-icon">📞</span>+1 (925) 381-3895</a>
                        <a class="contact-detail" href="mailto:Contact@starfinventures.com"><span class="contact-icon">✉️</span>Contact@starfinventures.com </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListingPage;
