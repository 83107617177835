import React, { useEffect, useState } from 'react';
import './Listing.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { state, city } from 'country-state-city';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const Listing = ({ firstname, lastname, email, commercialPropertyAddress, positionOfLister, imageURLs, state, city, role, isApproved, isRejected, _id, userEmail }) => {

  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

  const navigate = useNavigate();

  const [imageIndex, setImageIndex] = useState(0);
  const [status, setStatus] = useState('');


  const handleDelete = async (_id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this property?");
    if (isConfirmed){
      try {
        const response = await axios.delete(`${apiUrl}/api/deleteListing/${_id}`);
        console.log("Listing deleted: ", _id);
      } catch (error){
        console.log("ERROR DELETING LISTING: ", error)
      }
      window.location.reload();
    }
  }

  const arrowClickHandlerForward = () => {
    console.log(imageIndex);
    //console.log(imageURLs.length)  // REMOVE
    if (imageIndex+1 < imageURLs.length) {
      console.log('handling index change')
      setImageIndex((prevIndex) => {
        return prevIndex+1;
      })
    }
  }

  const arrowClickHandlerBackwards = () => {
    console.log(imageIndex);
    //console.log(imageURLs.length) // REMOVE
    if (imageIndex-1 >= 0) {
      console.log('handling index change')
      setImageIndex((prevIndex) => {
        return prevIndex-1;
      })
    }
  }

  useEffect(() => {
    //console.log('Index changed to: ', imageIndex); // REMOVE
  }, [imageIndex]);

  const handleAccept = async (_id) => {
    try {
      const response = await axios.post(`${apiUrl}/api/approveListing/${_id}`);
      console.log("Success", response.data);
      setStatus('accepted'); 
      window.location.reload();
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };
  
  const handleReject = async (_id) => {
    try {
      const response = await axios.post(`${apiUrl}/api/rejectListing/${_id}`);
      console.log("Success", response.data);
      setStatus('rejected');
      window.location.reload();
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }

  };

  const showDeleteButton = role === 'admin' || userEmail === email;

  const goToPropertyPage = () => {
    window.location.href = `/property/${_id}`;
  };

  return (

    <div className={`listing-container ${status}`}>
        <div className='listing-image-container'>
          {imageIndex - 1 >=0 && (
            <div className='arrow arrow-back' onClick={arrowClickHandlerBackwards}> 
              <ArrowBackIosIcon sx={{fontSize: '30px'}}/>
            </div>
          )}
          <img className='listing-image' src={imageURLs[imageIndex]}/>
          {imageIndex + 1 < imageURLs.length && (
            <div className='arrow arrow-forward' onClick={arrowClickHandlerForward}>
              <ArrowForwardIosIcon sx={{fontSize: '30px'}} />
            </div>
          )}
        </div>
        <div className='listing-text-container' onClick={goToPropertyPage}>
          <h3> {commercialPropertyAddress} </h3>
          <h6> State: {state} </h6>
          <h6> City: {city} </h6>
          <h6> Estimated Return: 20,000 per annum </h6>
          {role === 'admin' && (
            <h6> Lister Email: {email}, Name: {firstname} {lastname},  </h6>
          )}
        </div>
        {role === 'admin' && !isApproved && !isRejected && ( 
        <div className='listing-admin-container'>
            <button className='listing-admin-button-accept' onClick={() => handleAccept(_id)}>
              Accept property
            </button>
            <button className='listing-admin-button-reject' onClick={() => handleReject(_id)}>
              Reject property
            </button>

        </div>
        )}
        {role === 'admin' && !isApproved && isRejected && ( 
        <div className='listing-admin-container'>
            <button className='listing-admin-button-accept' onClick={() => handleAccept(_id)}>
              Remove rejected status and Accept property
            </button>
        </div>
        )}
        {role === 'admin' && isApproved && !isRejected && ( 
        <div className='listing-admin-container'>
            <button className='listing-admin-button-accept' onClick={() => handleReject(_id)}>
              Remove "Approved" and Reject property
            </button>
        </div>
        )}
        {showDeleteButton && (
        <div className="listing-delete-container">
          <button className="listing-delete-button" onClick={() => handleDelete(_id)}>
            Delete Listing
          </button>
        </div>
      )}
    </div>

  )

};

export default Listing;
