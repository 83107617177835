import React, { useState } from 'react';
import { auth } from '../../firebase/config';
import { sendPasswordResetEmail } from 'firebase/auth';
import './ForgotPassword.css'; 

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = (e) => {
    e.preventDefault();

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage("Password reset email sent. Please check your inbox.");
        setError('');
      })
      .catch((error) => {
        setError("Failed to send password reset email. Please make sure the email is correct and try again.");
        setMessage('');
        console.error("Error sending password reset email:", error);
      });
  };

  return (
    <div className="reset-password-wrapper">
      <h2 className="reset-password-title">Reset Password</h2>
      {error && <p className="reset-password-error">{error}</p>}
      {message && <p className="reset-password-message">{message}</p>}
      <form className="reset-password-form" onSubmit={handleResetPassword}>
        <input
          type="email"
          className="reset-password-input"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="reset-password-submit-btn">Send Reset Email</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
