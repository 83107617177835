import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AuthContext } from '../../../firebase/AuthProvider';

import './Footer.css'

const Footer = () => {

    const { currentUser } = useContext(AuthContext);

  return (
        <div className="footer-container">
            <div className="footer-content">
                <div className="footer-section">
                    <h4>About SkyfinVentures</h4>
                    <p> Making global change. </p>
                </div>
                <div className="footer-section">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/commission-and-referral-program">Commision and Referal Program</Link></li>
                        <li><Link to="/get-an-estimate">Get An Estimate</Link></li>
                        {!currentUser ?  <li><Link to="/sign-in">Sign in</Link></li> : <li> <Link to="/sign-in" > Profile </Link> </li>}

                        
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Contact Us</h4>
                    <p>Email: contact@starfinventures.com </p>
                    <p>Phone: +1 (925) 381-3895</p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© 2024 SKYFINVENTURES, All rights reserved.</p>

            </div>
        </div>
  )
}

export default Footer
