import React, { useState, useContext } from 'react';
import axios from 'axios'
import { AuthContext } from '../../firebase/AuthProvider';
import { auth, googleProvider } from '../../firebase/config';
import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './SignUpIn.css';

import GoogleIcon from '@mui/icons-material/Google';

const SignUpIn = () => {

  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

  const { currentUser, loading } = useContext(AuthContext);
  const navigate = useNavigate();


  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState(''); // State for managing errors messages
  const [message, setMessage] = useState(''); // State for managing success messages
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  // translating Firebase error codes to user-friendly messages
  const getFriendlyErrorMessage = (code) => {
    switch (code) {
      case 'auth/email-already-in-use':
        return 'This email is already in use. Please try another one.';
      case 'auth/weak-password':
        return 'Password should be at least 6 characters.';
      case 'auth/invalid-email':
        return 'Please enter a valid email address.';
      case 'auth/user-not-found':
        return 'No user found with this email.';
      case 'auth/wrong-password':
        return 'Wrong password. Please try again.';
      case 'auth/invalid-credential':
        return 'Invalid credentials. Please try again.';
      default:
        return 'An unexpected error occurred. Please try again later.';
    }
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        const { user } = result;
  
        const userData = {
          email: user.email,
          firebaseUid: user.uid,
          role: user.emailVerified ? 'user' : 'unverified'
        };
  
        try {
          await axios.post(`${apiUrl}/api/users/create`, userData);
          navigate('/profile');
        } catch (error) {
          setError("Failed to create or update user in MongoDB.");
          console.error("Error creating or updating user in MongoDB:", error);
        }
      })
      .catch((error) => {
        setError(getFriendlyErrorMessage(error.code));
      });
  };
  

  const registerWithEmailAndPassword = () => {
    if (email !== confirmEmail) {
      setError("Emails do not match!");
      return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        
        try {
          const { user } = userCredential;
          
          const userData = {
            email: user.email,
            firebaseUid: user.uid,
            role: user.emailVerified? 'user' : 'unverified'
          };
          await axios.post(`${apiUrl}/api/users/create`, userData); 
  
          sendEmailVerification(user)
            .then(() => {
              setMessage("Verification email sent. Please check your inbox."); // success message
              navigate('/verify-email');
            })
            .catch((error) => {
              setError(getFriendlyErrorMessage(error.code));
            });
        } catch (error) {
          setError("Failed to create user in MongoDB.");
          console.error("Error creating user in MongoDB:", error);
        }
      })
      .catch((error) => {
        setError(getFriendlyErrorMessage(error.code));
      });
  };

  const signInWithEmailAndPasswordHandler = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        if (!userCredential.user.emailVerified) {
          setError("Please verify your email before signing in.");
          return;
        }
        navigate('/profile');
      })
      .catch((error) => {
        setError(getFriendlyErrorMessage(error.code));
      });
  };

  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage("Password reset email sent. Please check your inbox.");
      })
      .catch((error) => {
        setError(getFriendlyErrorMessage(error.code));
      });
  };

  if (currentUser) {
    navigate('/profile');
  }

  return (
    <div className='sign-up-in-container'>

      {!isRegistering? <h3> Sign In</h3> : <h3> Sign Up </h3>}
      <h6> Start Profitting off you Unused Roof!</h6>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {message && <p style={{ color: 'green' }}>{message}</p>} 
      <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      {isRegistering && (
        <>
          <input type="email" placeholder="Confirm Email" value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} />
          <input type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          <input type="text" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </>
      )}
      <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      {!isRegistering && 
        <div className='sign-up-in-forgot-password-container'><a className='sign-up-in-forgot-password' href='/reset-password'>Forgot Password?</a></div>
      }
      {isRegistering ? (
        <>
          <button className='sign-in-button' onClick={registerWithEmailAndPassword}> Sign Up </button>
          <div className='register-button-container'> 
            <span>Already on StarFin? </span>
            <div onClick={() => setIsRegistering(false)}> Sign In</div>
          
          </div>
        </>
      ) : (
        <>
          <button className='sign-in-button' onClick={signInWithEmailAndPasswordHandler}>Sign In</button>
          <div className='sign-in-method-divider'> <hr/> or <hr/></div>
          <button className='sign-in-with-google-button' onClick={signInWithGoogle}>
            <GoogleIcon sx={{ color: 'red', fontSize: '21px' }} />
            <div>  Continue with Google  </div>  
          </button>
          <div className='register-button-container'> 
            <span>New to StarFin? </span>
            <div onClick={() => setIsRegistering(true)}> Join Now </div>
          
          </div>
        </>
      )}
    </div>
  );
};

export default SignUpIn;
