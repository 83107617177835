import React, { useState, useEffect } from 'react'
import './ViewProperties.css'
import axios from 'axios'

import Listing from '../sign-up-in/Profile/Listing';
import { State, City } from 'country-state-city';
import viewPropertiesFiltersBackground from '../../assets/images/view-properties-filters-background-2.jpg'

const ViewProperties = () => {

    const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

    const [listings, setListings] = useState([])
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    const [currentIndex, setCurrentIndex] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const fetchListings = async () => {
            try {
                let queryParams = {
                    limit: 8,
                    offset: currentIndex,
                };
                if (selectedState) queryParams.state = selectedState;
                if (selectedCity) queryParams.city = selectedCity;
    
                const response = await axios.get(`${apiUrl}/api/getApprovedListings`, {
                    params: queryParams,
                });
    
                
                setListings(currentIndex === 0 ? response.data.listings : [...listings, ...response.data.listings]);
                setHasMore(response.data.hasMore);
            } catch (error) {
                console.error("Failed to fetch listings:", error);
            }
        };
    
        fetchListings();
    }, [selectedState, selectedCity, currentIndex]);

    

    const handleStateChange = (e) => {
        setSelectedState(e.target.value);
        setSelectedCity('');
        setCurrentIndex(0);
        setListings([]);
    };

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
        //setCurrentIndex(0);
        setListings([]);
    };

    const loadMore = () => {
        setCurrentIndex(prevIndex => prevIndex + 8);
    };

  return (
    <div className='view-properties-container'>
      <h1> Availble Properties</h1>
      <div className='view-properties-filters'>
            <select className='view-properties-filter-button' value={selectedState} onChange={handleStateChange}>
                <option value="">Select State</option>
                {State.getStatesOfCountry('US').map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                    </option>
                ))}
            </select>
            <select className='view-properties-filter-button' value={selectedCity} onChange={e => setSelectedCity(e.target.value)}>
                <option className='view-properties-filter-button-default-option' value="">Select City</option>
                {selectedState && City.getCitiesOfState('US', selectedState).map((city) => (
                    <option className='view-properties-filter-button-default-option' key={city.name} value={city.name}>
                        {city.name}
                    </option>
                ))}
            </select>
      </div>

      <div className='view-properties-properties-container'>
        {listings.map(listing => (
            <Listing key={listing.id} {...listing} />
        ))}
        { listings.length === 0 && (
            <h2> No listings found for set filters. </h2>
        )}
      </div>
      <button style={{ display: hasMore? 'block' : 'none' }} onClick={loadMore} className='view-properties-load-more-button'> Load More </button>
    </div>
  )
}

export default ViewProperties
