import "./Map.css";
import PointerIcon from "../../assets/images/pointer.svg";
import ReactMapGl, { Marker } from "react-map-gl";
import { useState, useEffect } from "react";

// const TOKEN = 'pk.eyJ1Ijoic2FtbXlrYW8iLCJhIjoiY2x1aW54amplMDZwODJycDVxcnc4aGRjZyJ9.qO4vaM2TzcdFSMDoHLalxA';
const TOKEN = 'pk.eyJ1Ijoic2FtbXlrYW8iLCJhIjoiY2x1aW54amplMDZwODJycDVxcnc4aGRjZyJ9.qO4vaM2TzcdFSMDoHLalxA';
function Map({ longitude, latitude, updateCoordinates, address, setAddress }) {
    const [viewport, setViewport] = useState({
        latitude,
        longitude,
        zoom: 16,
    });

    const [marker, setMarker] = useState({
        latitude,
        longitude,
    });

    useEffect(() => {
        setViewport((oldViewport) => ({
        ...oldViewport,
        latitude,
        longitude,
        }));

        setMarker({
            latitude,
            longitude
        })

    }, [latitude, longitude, address]);

    const handleMarkerDrag = (event) => {
        const latitude = event.lngLat.lat;
        const longitude = event.lngLat.lng;
        setMarker({ latitude, longitude });
        updateCoordinates(latitude, longitude);

        

    };




    return (
        <div className="map">
        <ReactMapGl
            {...viewport}
            mapboxAccessToken={TOKEN}
            mapStyle="mapbox://styles/ali-starfin/clvrmjp7m01hz01rj9xq72eol"
            onMove={(event) => {
            setViewport(event.viewState);
            }}
        >
            <Marker
                latitude={marker.latitude}
                longitude={marker.longitude}
                draggable={true}
                onDragEnd={handleMarkerDrag}
            >
                <img className="marker" width='25px' src={PointerIcon} />
            </Marker>
        </ReactMapGl>
        </div>
    );
}

export default Map;