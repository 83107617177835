import React from 'react';
import './CheckOutPrograms.css';
import { Button } from '@mui/material';
import { BrowserRouter, Link, useNavigate } from 'react-router-dom';


const CheckOutPrograms = () => {

  let navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/commission-and-referral-program');
    window.scrollTo(0, 0);
  };


  return (

    <div className='check-out-programs-container'>
      <div className='check-out-programs-text'>
        Check out our commission and referral program!
      </div>
      <div className='check-out-programs-button-container'>
        <Link to='/commission-and-referral-program'>
          <button onClick={handleNavigate} className='check-out-programs-button'>
              Learn More!
          </button>
        </Link>
      </div>
    </div>
  )
}

export default CheckOutPrograms
