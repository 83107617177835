import React from 'react'
import './HowYouBenefit.css'
import homeBuildingQualificationImg from '../../assets/images/home-building-qualification.webp';

const HowYouBenefit = () => {
  return (
    <div className='how-you-benefit-container'>
      <div className='how-you-benefit-title'>
        HOW YOU BENEFIT
      </div>
      <div className='how-you-benefit-title-two'>
        Local Generation and Global Impact
      </div>
      <div className='how-you-benefit-sub-groups'>
        <div className='how-you-benefit-sub-groups-top'>
            <div className='how-you-benefit-sub-groups-left'>
                <div className='how-you-benefit-sub-groups-title'>Increase in Building Revenue and Valuation</div>
                <div className='how-you-benefit-sub-groups-text'> Unleash the potential of your commercial building by leasing out its rooftop. Witness a remarkable upswing in revenue and valuation, transforming a neglected rooftop into a thriving financial asset. Bid farewell to financial stress as your building’s dormant space evolves into a dynamic source of income, promising a brighter financial future for property owners.</div>
            </div>
            <div className='how-you-benefit-sub-groups-right'>
                <div className='how-you-benefit-sub-groups-title'>Positive Environmental Impact</div>
                <div className='how-you-benefit-sub-groups-text'> When your building switches to solar energy, you’re not just transforming your building’s energy source – you’re contributing to a greener, more sustainable future. Reduce your carbon footprint and join the movement towards cleaner energy, making a meaningful difference for the environment while powering your building with the limitless potential of the sun.</div>
            </div>
        </div>
        <div className='how-you-benefit-sub-groups-bottom'>
            <div className='how-you-benefit-sub-groups-left'>
                <div className='how-you-benefit-sub-groups-title'>Outage Protection</div>
                <div className='how-you-benefit-sub-groups-text'> With the innovative solar billing plan (NEM 3.0), our clients are encouraged to invest in a battery for surplus energy storage. In the event of an outage, the surplus energy stored in the battery becomes a lifeline, ensuring continuous power supply to your building. Keep your operations running smoothly, even in challenging times, with a reliable and sustainable energy solution.</div>
            </div>
            <div className='how-you-benefit-sub-groups-right'>
                <div className='how-you-benefit-sub-groups-title'>Roof Renewal Assurance</div>
                <div className='how-you-benefit-sub-groups-text'> When you lease your rooftop for solar installations, our commitment goes beyond power generation. If your roof can’t support the panels for 20 years or needs structural enhancements, our clients cover the costs. This means not only a sustainable energy source but also a brand-new roof for building owners – all at no extra expense.</div>
            </div>
        </div>
      </div>
      <div className='how-you-benefit-building-qualification'>
        <div className='how-you-benefit-building-qualification-left-side'>
            <img className='how-you-benefit-building-qualification-left-side-image' src={homeBuildingQualificationImg} />
        </div>
        <div className='how-you-benefit-building-qualification-right-side'>
            <div className='how-you-benefit-building-qualification-right-side-title'>
                Building qualifications
            </div>
            <div className='how-you-benefit-building-qualification-right-side-text'>
                <div className='how-you-benefit-building-qualification-right-side-text-title'>
                    Check if Your Building is Eligible
                </div>
                <ul className='how-you-benefit-building-qualification-right-side-text-list'>
                    <li> Your rooftop has a minimum of 20,000 square feet of usable space. </li>
                    <li> The rooftop is mostly flat. </li>
                    <li> There is at least one active tenant within in the building. </li>
                </ul>
            </div>
            <div className='how-you-benefit-building-qualification-right-side-button'>
                
            </div>
        </div>
      </div>
    </div>
  )
}

export default HowYouBenefit
