import React from 'react'
import './FAQ.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = ({faq, index, toggleFAQ}) => {



  return (
    <div className='faq-container' onClick={() => toggleFAQ(index)}>
      <div className='faq-container-top'>
        <div>{faq.question}</div>
        <ExpandMoreIcon sx={{fontSize: '40px'}} />
      </div>

        {faq.open && (
            <div className='faq-container-answer'>  
                {faq.answer}
            </div>
        )}

    </div>
  )
}

export default FAQ
