import React from 'react'
import './FirstSection.css'
import { useNavigate } from "react-router-dom"
import homeFirstSection from '../../assets/images/home-first-section-image.webp'




const FirstSection = () => {

    const navigate = useNavigate();

    const handleEnterAddressButton = () => {
        navigate('/get-an-estimate')
    }


  return (
    <div className='home-first-section-container'>
      
        <div className='home-first-section-left-side'>
            <div className='home-first-section-left-side-top'>
                Our Marketplace Finds The Best Roof Tenants For Your Commercial & Industrial Properties!
            </div>
            <div className='home-first-section-left-side-middle'>
                Get Paid by Renting Out Your Roof!
            </div>
            <div className='home-first-section-left-side-bottom'>
                    <button onClick={handleEnterAddressButton} className='home-first-section-left-side-bottom-button' sx={{height: '65px', width: '240px', fontFamily: "'Roboto', sans-serif"}} variant='contained'> Enter Your Address </button>
                <p className='home-first-section-left-side-bottom-text'> With One Click, Discover Your Properties Roof Rental and Upside Potential! </p>
            </div>

        </div>

        <div className='home-first-section-right-side'>
            <img className='home-first-section-right-side-image' src={homeFirstSection} />
        </div>

    </div>
  )
}

export default FirstSection
