import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import { auth } from './config';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";
    const [currentUser, setCurrentUser] = useState(null);
    const [userRole, setUserRole] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setCurrentUser(user);
                try {
                    const response = await axios.get(`${apiUrl}/api/users/role/${user.uid}`);
                    setUserRole(response.data.role);
                } catch (error) {
                    console.error('Error fetching user role:', error);
                    setUserRole('user');
                }
            } else {
                setCurrentUser(null);
                setUserRole('');
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const contextValue = {
        currentUser,
        userRole,
        loading,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};
